import './src/styles/global.scss'
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const appModalRoot = document.createElement('div');
appModalRoot.id = 'bottom-sheet-modal';
document.body.appendChild(appModalRoot);

// Add stripe script tag
const script = document.createElement('script');
script.src = 'https://js.stripe.com/v3/';
document.body.appendChild(script);