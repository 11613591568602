// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-activities-activities-tsx": () => import("./../../../src/templates/activities/activities.tsx" /* webpackChunkName: "component---src-templates-activities-activities-tsx" */),
  "component---src-templates-activities-single-activity-single-activity-tsx": () => import("./../../../src/templates/activities/single-activity/single-activity.tsx" /* webpackChunkName: "component---src-templates-activities-single-activity-single-activity-tsx" */),
  "component---src-templates-book-ticket-book-ticket-tsx": () => import("./../../../src/templates/book-ticket/book-ticket.tsx" /* webpackChunkName: "component---src-templates-book-ticket-book-ticket-tsx" */),
  "component---src-templates-booked-trips-booked-trips-tsx": () => import("./../../../src/templates/booked-trips/booked-trips.tsx" /* webpackChunkName: "component---src-templates-booked-trips-booked-trips-tsx" */),
  "component---src-templates-company-signup-company-signup-tsx": () => import("./../../../src/templates/company-signup/company-signup.tsx" /* webpackChunkName: "component---src-templates-company-signup-company-signup-tsx" */),
  "component---src-templates-index-index-tsx": () => import("./../../../src/templates/index/index.tsx" /* webpackChunkName: "component---src-templates-index-index-tsx" */),
  "component---src-templates-login-login-tsx": () => import("./../../../src/templates/login/login.tsx" /* webpackChunkName: "component---src-templates-login-login-tsx" */),
  "component---src-templates-my-passes-my-passes-tsx": () => import("./../../../src/templates/my-passes/my-passes.tsx" /* webpackChunkName: "component---src-templates-my-passes-my-passes-tsx" */),
  "component---src-templates-profile-profile-tsx": () => import("./../../../src/templates/profile/profile.tsx" /* webpackChunkName: "component---src-templates-profile-profile-tsx" */),
  "component---src-templates-purchase-cart-cart-tsx": () => import("./../../../src/templates/purchase/cart/cart.tsx" /* webpackChunkName: "component---src-templates-purchase-cart-cart-tsx" */),
  "component---src-templates-purchase-checkout-checkout-tsx": () => import("./../../../src/templates/purchase/checkout/checkout.tsx" /* webpackChunkName: "component---src-templates-purchase-checkout-checkout-tsx" */),
  "component---src-templates-signup-signup-tsx": () => import("./../../../src/templates/signup/signup.tsx" /* webpackChunkName: "component---src-templates-signup-signup-tsx" */),
  "component---src-templates-terms-and-conditions-terms-and-conditions-tsx": () => import("./../../../src/templates/terms-and-conditions/terms-and-conditions.tsx" /* webpackChunkName: "component---src-templates-terms-and-conditions-terms-and-conditions-tsx" */)
}

